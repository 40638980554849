import { BalancePaymentDialog } from '@/components/profile/modals/BalancePaymentDialog';
import { Box, Button, Divider, Grid, Skeleton, Theme, Tooltip, Typography } from '@mui/material';
import { CardBlock } from '@/components/profile/CardBlock';
import { CardDTO } from '@/types/card/CardDTO';
import { CheckQrStatusRequestDTO } from '@/types/sbp/CheckQrStatusRequestDTO';
import { CheckStatusOrderSbpDTO } from '@/types/sbp/CheckStatusOrderSbpDTO';
import { CheckedStatusQrDTO } from '@/types/sbp/CheckedStatusQrDTO';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { MobileBar } from '@/components/home/MobileBar';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { QrStatus } from '@/types/sbp/QrStatus';
import { ROUTE_PROFILE } from '@/app/routes';
import { RootState } from '@/app/store';
import { SbpBalancePaymentDialog } from '@/components/common/sbp/SbpBalancePaymentDialog';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SbpOrderStatus } from '@/types/sbp/SbpOrderStatus';
import { UserDTO } from '@/types/user/UserDTO';
import { WalletIcon } from '@/app/icons/profile/WalletIcon';
import { apiSlice } from '@/services/api/apiSlice';
import { clearBalanceSbpState, getBalanceFillAmount, getSbpQR, isBalanceSuccessFill } from '@/services/sbpSlice';
import { formatCurrency } from '@/utils/string-utils';
import { getCostWithCommission } from '@/utils/currency-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCheckStatusPaymentMutation, useGetQrStatusMutation } from '@/services/api/paymentApiSlice';
import { useGetUserBalanceQuery } from '@/services/api/balanceApiSlice';
import { useGetUserCardsQuery } from '@/services/api/cardApiSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useEffect, useState } from 'react';

interface Props {
  isMobile?: boolean;
  user?: UserDTO;
  userLoading?: boolean;
}

export const ProfileBalance: FC<Props> = (props: Props) => {
  const { isMobile, user, userLoading } = props;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const {
    data: balance,
    isFetching: isBalanceFetching,
    isLoading: isBalanceLoading,
  } = useGetUserBalanceQuery(authUser?.id, { skip: !authUser });

  const {
    data: cards,
    isLoading: isCardsLoading,
    isFetching: isCardsFetching,
  } = useGetUserCardsQuery(authUser?.id, { skip: !authUser });

  const [getQrStatus] = useGetQrStatusMutation();

  const balanceLoading: boolean = isBalanceFetching || isBalanceLoading;
  const cardsLoading: boolean = isCardsLoading || isCardsFetching;

  const [open, setOpen] = useState<boolean>(false);

  const isBalanceFillSuccess: boolean = useAppSelector(isBalanceSuccessFill);
  const [openSbpBalance, setOpenSbpBalance] = useState<boolean>(true);
  const balanceSbpFillAmount: number = useAppSelector(getBalanceFillAmount);
  const sbpQR: CreatedSbpQrDTO | undefined = useAppSelector((state: RootState): CreatedSbpQrDTO | undefined =>
    getSbpQR(state, SbpMethod.BALANCE)
  );

  const handleClickTopUpBalance = (): void => {
    if (user?.phone) {
      setOpen(true);
    } else {
      enqueueSnackbar('Добавьте номер телефона в настройках профиля.', {
        variant: 'error',
      });
    }
  };

  const location = useLocation();
  const history = useHistory();
  const [checkStatusPayment] = useCheckStatusPaymentMutation();

  useEffect((): void => {
    const handleCheckStatusPayment = async (sbpOrderId: string | null): Promise<void> => {
      if (sbpOrderId !== null) {
        await checkStatusPayment({ sbpOrderId: sbpOrderId })
          .unwrap()
          .then((response: CheckStatusOrderSbpDTO): void => {
            if (response.orderStatus && response.orderStatus === SbpOrderStatus.ACCEPTED) {
              apiSlice.util.invalidateTags(['Balance', 'BalanceHistory']);
            }
          });
      }
    };

    const queryParams: URLSearchParams = new URLSearchParams(location.search);
    if (queryParams.has('orderId')) {
      handleCheckStatusPayment(queryParams.get('orderId'));
      history.replace(ROUTE_PROFILE);
    }
  }, [checkStatusPayment, history, location.search]);

  useEffect((): void => {
    if (sbpQR) {
      const dto: CheckQrStatusRequestDTO = {
        qrId: sbpQR.qrId,
      };
      getQrStatus(dto)
        .unwrap()
        .then((response: CheckedStatusQrDTO): void => {
          if (response.qrStatus !== QrStatus.STARTED) {
            dispatch(clearBalanceSbpState());
            setOpen(false);
          }
        })
        .catch(() => {
          dispatch(clearBalanceSbpState());
          setOpen(false);
        });
    }
  }, [dispatch, getQrStatus, sbpQR]);

  return (
    <Fragment>
      <Box
        sx={(theme: Theme) => ({
          background: theme.colors.grayBackground,
          borderRadius: '12px',
          padding: theme.spacing(3, 2, 4),
          minWidth: isMobile ? 'none' : '290px',
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2, 3),
          },
        })}>
        <Grid container={true} direction={'column'} spacing={isMobile ? 2 : 4}>
          <Grid item={true}>
            <Grid container={true} direction={'column'} spacing={2}>
              <Grid item={true}>
                <Grid container={true} direction={'row'} spacing={2}>
                  <Grid item={true} minWidth={(theme: Theme) => (isMobile ? theme.spacing(8) : 'auto')}>
                    <Box
                      sx={(theme: Theme) => ({
                        borderRadius: '50%',
                        color: theme.colors.primary,
                        background: theme.colors.primaryLight,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: theme.spacing(4),
                        height: theme.spacing(4),
                        [theme.breakpoints.down('sm')]: {
                          width: theme.spacing(6),
                          height: theme.spacing(6),
                        },
                      })}>
                      <WalletIcon
                        sx={(theme: Theme) => ({
                          width: theme.spacing(2),
                          height: theme.spacing(2),
                          [theme.breakpoints.down('sm')]: {
                            width: theme.spacing(3),
                            height: theme.spacing(3),
                          },
                        })}
                      />
                    </Box>
                  </Grid>
                  <Grid item={true}>
                    <Grid container={true} direction={'column'} spacing={isMobile ? 0 : 1}>
                      <Grid item={true}>
                        <Typography
                          variant={'h1'}
                          sx={(theme: Theme) => ({
                            fontSize: '32px',
                            lineHeight: '32px',
                            [theme.breakpoints.down('sm')]: {
                              fontSize: '24px',
                              lineHeight: '32px',
                            },
                          })}>
                          {balanceLoading ? (
                            <Skeleton height={'32px'} width={'96px'} />
                          ) : (
                            formatCurrency(balance?.amount || 0)
                          )}
                        </Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
                          {'Баланс личного кабинета'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!isMobile && (
                <Grid item={true}>
                  <Tooltip
                    title={
                      user?.phone
                        ? ''
                        : 'Для пополнения баланса необходимо добавить номер телефона в настройках профиля.'
                    }
                    disableInteractive={true}>
                    <div>
                      <Button
                        disabled={balanceLoading || userLoading}
                        variant={'contained'}
                        fullWidth={true}
                        onClick={() => handleClickTopUpBalance()}>
                        {'Пополнить баланс'}
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          {cards && cards.length > 0 && (
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={2}>
                {cards.map((card: CardDTO, index: number) => (
                  <Fragment key={card.id}>
                    {(isMobile || index !== 0) && (
                      <Grid item={true}>
                        <Divider />
                      </Grid>
                    )}
                    <Grid item={true}>
                      <CardBlock card={card} isLoading={cardsLoading} />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        {isMobile && (
          <MobileBar>
            <Tooltip
              title={
                user?.phone ? '' : 'Для пополнения баланса необходимо добавить номер телефона в настройках профиля.'
              }
              disableInteractive={true}>
              <div>
                <FixedButton
                  disabled={balanceLoading || userLoading}
                  onClick={() => handleClickTopUpBalance()}
                  variant={'contained'}>
                  {'Пополнить баланс'}
                </FixedButton>
              </div>
            </Tooltip>
          </MobileBar>
        )}
      </Box>
      <BalancePaymentDialog
        open={open}
        currentBalance={balance?.amount || 0}
        cards={cards || []}
        isMobile={isMobile}
        onClose={() => setOpen(false)}
        openSecondaryView={() => setOpenSbpBalance(true)}
      />
      {(sbpQR || isBalanceFillSuccess) && (
        <SbpBalancePaymentDialog
          sbpQR={sbpQR}
          isBalanceFillSuccess={isBalanceFillSuccess}
          open={openSbpBalance}
          amount={getCostWithCommission(balanceSbpFillAmount, PaymentMethod.SBP)}
          email={authUser?.email || ''}
          onClose={() => setOpenSbpBalance(false)}
          isMobile={isMobile}
        />
      )}
    </Fragment>
  );
};
