import * as Yup from 'yup';
import { AmountDTO } from '@/types/AmountDTO';
import { AppDispatch, RootState } from '@/app/store';
import { AuthorDTO } from '@/types/AuthorDTO';
import { CardAmountButtonsGroup } from '@/components/create/card/buttons/CardAmountButtonsGroup';
import { CardContractCondition } from '@/components/create/card/CardContractCondition';
import { CardDTO } from '@/types/card/CardDTO';
import { CardPaymentDialog } from '@/components/card/CardPaymentDialog';
import { CardService } from '@/types/card/CardService';
import { CheckOrderStatusRequestDTO } from '@/types/sbp/CheckOrderStatusRequestDTO';
import { CheckQrStatusRequestDTO } from '@/types/sbp/CheckQrStatusRequestDTO';
import { CheckedStatusOrderDTO } from '@/types/sbp/CheckedStatusOrderDTO';
import { CheckedStatusQrDTO } from '@/types/sbp/CheckedStatusQrDTO';
import { CreateCardDTO } from '@/types/card/CreateCardDTO';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { MESSAGE_FETCH_ERROR } from '@/utils/validation-utils';
import { MobileBar } from '@/components/home/MobileBar';
import { OnlinePaymentResponseDTO } from '@/types/OnlinePaymentResponseDTO';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { PhoneNumberField } from '@/components/common/field/PhoneNumberField';
import { PolicyLoadingButton } from '@/components/common/button/PolicyLoadingButton';
import { QrStatus } from '@/types/sbp/QrStatus';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SbpOrderStatus } from '@/types/sbp/SbpOrderStatus';
import { UserDTO } from '@/types/user/UserDTO';
import {
  clearCardCreateSbpState,
  getCardPaymentMethod,
  getCardPaymentProvider,
  getSbpOrderId,
  getSbpQR,
  setCardCreateFillAmount,
  setCardCreateFillSuccess,
  setCardCreateSbpQR,
  setCardEmail,
  setCardPaymentMethod,
  setCardPaymentProvider,
  setSbpOrderId,
} from '@/services/sbpSlice';
import { emailSchema } from '@/validation/emailSchema';
import { formatUserFullName } from '@/utils/string-utils';
import { fullNameSchema } from '@/validation/fullNameSchema';
import { getAuthor } from '@/services/createFormSlice';
import { getCurrentUser } from '@/services/authSlice';
import { phoneFieldSchema } from '@/validation/phoneSchema';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCardBalanceRefillMutation } from '@/services/api/sbpPaymentApiSlice';
import {
  useCreateBillUnpaidCardMutation,
  useGetOrderStatusMutation,
  useGetQrStatusMutation,
} from '@/services/api/paymentApiSlice';
import { useCreateCardMutation, useGetAmountsQuery } from '@/services/api/cardApiSlice';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

interface FormValues {
  authorFullName: string;
  authorPhoneNumber: string;
  authorEmail: string;
  authorConfirmEmail: string;
}

const validationSchema = Yup.object({
  authorFullName: fullNameSchema,
  authorPhoneNumber: phoneFieldSchema,
  authorEmail: emailSchema.oneOf([Yup.ref('authorConfirmEmail')], 'Поля не совпадают'),
  authorConfirmEmail: emailSchema.oneOf([Yup.ref('authorEmail')], 'Поля не совпадают'),
});

interface Props {
  isMobile?: boolean;
}

export const CreateCardForm: FC<Props> = (props: Props) => {
  const { isMobile } = props;

  const dispatch: AppDispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const cardPaymentMethod: PaymentMethod = useAppSelector(getCardPaymentMethod);
  const cardPaymentProvider: PaymentProvider | undefined = useAppSelector(getCardPaymentProvider);
  const sbpOrderId: string | undefined = useAppSelector((state: RootState) => {
    return getSbpOrderId(state, SbpMethod.CARD, CardService.CREATE);
  });
  const sbpQR: CreatedSbpQrDTO | undefined = useAppSelector((state: RootState) => {
    return getSbpQR(state, SbpMethod.CARD, CardService.CREATE);
  });

  const { data: amounts } = useGetAmountsQuery();
  const { data: user } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });
  const [createCard, { isLoading: isCardCreating }] = useCreateCardMutation();
  const [payCardBill, { isLoading: isOnlinePayLoading }] = useCreateBillUnpaidCardMutation();
  const [getOrderStatus] = useGetOrderStatusMutation();
  const [getQrStatus, { isLoading: isQrStatusChecking }] = useGetQrStatusMutation();
  const [refillCard, { isLoading: isCardRefilling }] = useCardBalanceRefillMutation();

  const [amountId, setAmountId] = useState<number>(1);
  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(typeof sbpQR !== 'undefined');
  const [createCardDTO, setCreateCardDTO] = useState<CreateCardDTO>();

  const initialValues: FormValues = {
    authorFullName: author?.name || (user ? formatUserFullName(user) : ''),
    authorPhoneNumber: author?.phone || (user && user.phone ? user.phone : ''),
    authorEmail: author?.email || (user ? user.email : ''),
    authorConfirmEmail: author?.email || (user ? user.email : ''),
  };

  const [policyChecked, setPolicyChecked] = useState<boolean>(false);

  const submitForm = async (values: FormValues): Promise<void> => {
    const createCardDTO: CreateCardDTO = {
      author: values.authorFullName,
      email: values.authorEmail,
      phone: values.authorPhoneNumber,
      amount: amountId,
    };
    setCreateCardDTO(createCardDTO);
    setOpenPaymentDialog(true);
    dispatch(setCardCreateFillSuccess(false));
  };

  const handleSbpOrderId = (orderId?: string, cardService?: CardService): void => {
    setSbpOrderId(dispatch, SbpMethod.CARD, orderId, cardService, false, false);
  };

  const handleCardCreateAndPayment = async (): Promise<void> => {
    if (createCardDTO) {
      await createCard(createCardDTO)
        .unwrap()
        .then((dto: CardDTO): void => {
          if (cardPaymentMethod === PaymentMethod.SBP) {
            handlePayBySBP(dto);
          }
          // TODO: Use this method after implement FSIN-2220.
          // if (cardPaymentMethod === PaymentMethod.ONLINE) {
          //   handlePayByOnline(dto);
          // }
        })
        .catch((e: { status: number; data: ErrorDTO }): void => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    }
  };

  const handleCardCreateState = (cardEmail: string, amount: number): void => {
    dispatch(clearCardCreateSbpState());
    dispatch(setCardCreateFillSuccess(false));
    dispatch(setCardCreateFillAmount(amount));
    dispatch(
      setCardEmail({
        email: cardEmail,
        cardService: CardService.CREATE,
      })
    );
  };

  const handlePayBySBP = async (responseCardDTO: CardDTO): Promise<void> => {
    if (responseCardDTO) {
      await refillCard({
        cardUUID: responseCardDTO.uuid,
        amount: responseCardDTO.balance,
      })
        .unwrap()
        .then((dto: CreatedSbpQrDTO): void => {
          handleCardCreateState(responseCardDTO.email, responseCardDTO.balance);
          dispatch(setCardCreateSbpQR(dto));
        })
        .catch((e: { status: number; data: ErrorDTO }): void => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    }
  };

  const handlePayByOnline = async (provider: PaymentProvider): Promise<void> => {
    const windowReference: Window | null = window.open();
    if (createCardDTO) {
      await createCard(createCardDTO)
        .unwrap()
        .then((dto: CardDTO) => {
          return payCardBill({ uuid: dto.uuid, provider: provider }).unwrap();
        })
        .then((dto: OnlinePaymentResponseDTO): void => {
          // Fix for Safari (window.open doesn't work in async functions)
          if (windowReference) {
            windowReference.location = dto.paymentUrl;
          }
          handleCardCreateState(createCardDTO.email, createCardDTO.amount);
          handleSbpOrderId(dto.orderId, CardService.CREATE);
        })
        .catch((e: { status: number; data: ErrorDTO }): void => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    }
  };

  useEffect((): void => {
    if (sbpQR) {
      const dto: CheckQrStatusRequestDTO = {
        qrId: sbpQR.qrId,
      };
      getQrStatus(dto)
        .unwrap()
        .then((response: CheckedStatusQrDTO): void => {
          if (response.qrStatus !== QrStatus.STARTED) {
            dispatch(clearCardCreateSbpState());
            setOpenPaymentDialog(false);
          }
        })
        .catch((): void => {
          dispatch(clearCardCreateSbpState());
          setOpenPaymentDialog(false);
        });
    }
  }, [dispatch, getQrStatus, sbpQR]);

  useEffect((): void => {
    const timerId: NodeJS.Timer = setInterval((): void => {
      if (sbpOrderId) {
        const dto: CheckOrderStatusRequestDTO = {
          orderId: sbpOrderId,
        };
        getOrderStatus(dto)
          .unwrap()
          .then((response: CheckedStatusOrderDTO): void => {
            if (response?.orderStatus === SbpOrderStatus.ACCEPTED) {
              dispatch(setCardCreateFillSuccess(true));
              clearInterval(timerId);
            } else if (
              response?.orderStatus === SbpOrderStatus.CANCELED ||
              response?.orderStatus === SbpOrderStatus.REJECTED ||
              response?.orderStatus === SbpOrderStatus.ERROR
            ) {
              enqueueSnackbar('Платёж отклонён', { variant: 'error' });
              dispatch(clearCardCreateSbpState());
              setOpenPaymentDialog(false);
              clearInterval(timerId);
            }
          })
          .catch((): void => {
            dispatch(clearCardCreateSbpState());
            setOpenPaymentDialog(false);
          });
      }
    }, 30000);
  }, [dispatch, enqueueSnackbar, getOrderStatus, sbpOrderId]);

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={submitForm}>
        {(formikProps: FormikProps<FormValues>) => {
          const { values, errors, touched, submitForm, setFieldValue, setFieldTouched, isValid } = formikProps;
          const handleSetFieldValue = (field: string, value?: string) => {
            setFieldTouched(field, true, false);
            setFieldValue(field, value, true);
          };
          return (
            <Grid container={true} direction={'column'} wrap={'nowrap'}>
              <Grid item={true} paddingBottom={isMobile ? 6 : 7}>
                <CardAmountButtonsGroup
                  amounts={amounts}
                  currentAmountValue={amountId}
                  setAmountValue={setAmountId}
                  isMobile={isMobile}
                />
              </Grid>
              <Grid item={true} paddingBottom={isMobile ? 6 : 7}>
                <Grid container={true} spacing={isMobile ? 6 : 2} direction={isMobile ? 'column' : 'row'}>
                  <Grid item={true} xs={8}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Ваше ФИО'}
                      value={values.authorFullName ? String(values.authorFullName) : ''}
                      error={touched.authorFullName && Boolean(errors.authorFullName)}
                      helperText={touched.authorFullName ? errors.authorFullName : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        handleSetFieldValue('authorFullName', e.target.value);
                      }}
                      disabled={isCardCreating}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <PhoneNumberField
                      fullWidth={true}
                      name={'authorPhoneNumber'}
                      label={'Телефон'}
                      value={values.authorPhoneNumber ? String(values.authorPhoneNumber) : ''}
                      error={touched.authorPhoneNumber && Boolean(errors.authorPhoneNumber)}
                      helperText={touched.authorPhoneNumber ? errors.authorPhoneNumber : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        handleSetFieldValue(e.target.name, e.target.value);
                      }}
                      disabled={isCardCreating}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={isMobile ? 6 : 1}>
                  <Grid item={true}>
                    <Grid container={true} spacing={isMobile ? 6 : 2} direction={isMobile ? 'column' : 'row'}>
                      <Grid item={true} xs={true}>
                        <OutlinedField
                          fullWidth={true}
                          name={'authorEmail'}
                          label={'Контактный адрес эл. почты'}
                          value={values.authorEmail ? String(values.authorEmail) : ''}
                          error={touched.authorEmail && Boolean(errors.authorEmail)}
                          helperText={touched.authorEmail ? errors.authorEmail : ''}
                          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                            handleSetFieldValue('authorEmail', e.target.value);
                          }}
                          disabled={isCardCreating}
                        />
                      </Grid>
                      <Grid item={true} xs={true}>
                        <OutlinedField
                          fullWidth={true}
                          name={'authorConfirmEmail'}
                          label={'Подтвердите адрес эл. почты'}
                          value={values.authorConfirmEmail ? String(values.authorConfirmEmail) : ''}
                          error={touched.authorConfirmEmail && Boolean(errors.authorConfirmEmail)}
                          helperText={touched.authorConfirmEmail ? errors.authorConfirmEmail : ''}
                          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                            handleSetFieldValue('authorConfirmEmail', e.target.value);
                          }}
                          disabled={isCardCreating}
                        />
                      </Grid>
                      {isMobile ? (
                        <MobileBar>
                          <FixedButton
                            color={'primary'}
                            variant={'contained'}
                            onClick={submitForm}
                            disabled={isCardCreating || !isValid || !policyChecked}>
                            {'Заказать карту'}
                          </FixedButton>
                        </MobileBar>
                      ) : (
                        <Grid item={true} xs={true}>
                          <PolicyLoadingButton
                            variant={'contained'}
                            type={'submit'}
                            fullWidth={true}
                            disabled={isCardCreating || !isValid || !policyChecked}
                            isLoading={isCardCreating}
                            policyChecked={policyChecked}
                            onPolicyChange={setPolicyChecked}
                            onClick={submitForm}>
                            {'Заказать карту'}
                          </PolicyLoadingButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isMobile && (
                    <Grid item={true}>
                      <CardContractCondition policyChecked={policyChecked} onPolicyChange={setPolicyChecked} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
      <CardPaymentDialog
        open={openPaymentDialog && !isQrStatusChecking}
        paymentAmount={amounts?.find((amount: AmountDTO): boolean => amount.id === amountId)?.value || 0}
        cardService={CardService.CREATE}
        paymentMethod={cardPaymentMethod}
        onPaymentMethodChange={(method: PaymentMethod) => dispatch(setCardPaymentMethod(method))}
        paymentProvider={cardPaymentProvider}
        onPaymentProviderChange={(provider?: PaymentProvider) => dispatch(setCardPaymentProvider(provider))}
        handlePayment={(): Promise<void> =>
          cardPaymentMethod === PaymentMethod.SBP
            ? handleCardCreateAndPayment()
            : handlePayByOnline(cardPaymentProvider || PaymentProvider.ALFA)
        }
        sbpOrderId={sbpOrderId}
        sbpQR={sbpQR}
        isMobile={isMobile}
        isLoading={isCardCreating || isCardRefilling || isOnlinePayLoading}
        onClose={(): void => {
          setOpenPaymentDialog(false);
        }}
      />
    </Fragment>
  );
};
