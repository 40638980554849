import { Grid, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  label: string;
}

export const ErrorPage: FC<Props> = (props) => {
  const { label, children } = props;

  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'}>
      <Grid item={true} mb={4}>
        <Typography variant={'h2'}>{label}</Typography>
      </Grid>
      <Grid item={true}>{children}</Grid>
    </Grid>
  );
};
