import { Box, Grid, IconButton, Skeleton, Stack, Theme, Typography } from '@mui/material';
import { SettingsIcon } from '@/app/icons/profile/SettingsIcon';
import { UserDTO } from '@/types/user/UserDTO';
import { formatPhoneNumber } from '@/utils/string-utils';
import React, { FC, Fragment, useState } from 'react';
import SettingsDialog from '@/components/profile/modals/SettingsDialog';

interface Props {
  user?: UserDTO;
  isLoading?: boolean;
}

export const ProfileSettings: FC<Props> = (props: Props) => {
  const { user, isLoading } = props;

  const [open, setOpen] = useState<boolean>(false);

  const isMobile = false;

  return (
    <Fragment>
      <Box
        sx={(theme: Theme) => ({
          background: theme.colors.grayBackground,
          borderRadius: '12px',
          padding: theme.spacing(3, 2, 4),
        })}>
        <Grid container={true} direction={'column'} spacing={3}>
          <Grid item={true}>
            <Grid container={true} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item={true}>
                <Typography variant={'label1'} component={'div'} color={(theme: Theme) => theme.colors.grayText}>
                  {'Моя информация'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <IconButton size={'dense'} color={'primary'} disabled={isLoading} onClick={() => setOpen(true)}>
                  <SettingsIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Stack direction={'column'} spacing={0.5}>
              <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
                {'Телефон'}
              </Typography>
              <Typography variant={'body1'}>
                {isLoading ? <Skeleton /> : user?.phone ? formatPhoneNumber(user?.phone) : 'Не указан'}
              </Typography>
            </Stack>
          </Grid>
          <Grid item={true}>
            <Stack direction={'column'} spacing={0.5}>
              <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
                {'Электронная почта'}
              </Typography>
              <Typography variant={'body1'}>{isLoading ? <Skeleton /> : user?.email || 'Не указан'}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <SettingsDialog
        open={open}
        user={user}
        handleOpenState={setOpen}
        isMobile={isMobile}
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
