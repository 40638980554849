import { CardImportDialog } from '@/components/card/import/CardImportDialog';
import { Footer } from '@/components/main/footer/Footer';
import { Grid, IconButton, Skeleton, Theme, Typography } from '@mui/material';
import { MobileBalanceHistory } from '@/components/balance/MobileBalanceHistory';
import { ProfileBalance } from '@/components/profile/ProfileBalance';
import { SettingsIcon } from '@/app/icons/profile/SettingsIcon';
import { UserDTO } from '@/types/user/UserDTO';
import { formatUserFullNameShort } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useGetUserCardsQuery } from '@/services/api/cardApiSlice';
import React, { FC, Fragment, useEffect, useState } from 'react';
import SettingsDialog from '@/components/profile/modals/SettingsDialog';

export const MobileProfilePage: FC = () => {
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const {
    data: user,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });

  const { data: cards } = useGetUserCardsQuery(authUser?.id, {
    skip: !authUser || !user?.cardImportRequired,
  });
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const userLoading: boolean = isUserFetching || isUserLoading;

  useEffect((): void => {
    if (cards && cards?.length > 0 && user?.cardImportRequired) {
      setOpenImport(true);
    }
  }, [cards, user?.cardImportRequired]);

  return (
    <Fragment>
      <Grid container={true} direction={'column'} mt={-4} sx={{ minHeight: '100%', flexGrow: 1 }}>
        <Grid item={true} mb={2}>
          <Grid container={true} justifyContent={'space-between'} alignItems={'center'} wrap={'nowrap'}>
            <Grid item={true}>
              <Typography variant={'h1'}>
                {userLoading ? <Skeleton width={'240px'} /> : formatUserFullNameShort(user)}
              </Typography>
            </Grid>
            <Grid item={true}>
              <IconButton
                color={'primary'}
                disabled={userLoading}
                onClick={(): void => setOpenSettings(true)}
                sx={(theme: Theme) => ({
                  padding: '10px',
                  background: theme.colors.grayBackground,
                  '&:hover': {
                    background: theme.colors.grayBackground,
                  },
                })}>
                <SettingsIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <ProfileBalance isMobile={true} user={user} userLoading={userLoading} />
        </Grid>
        <Grid item={true} mt={4}>
          <MobileBalanceHistory />
        </Grid>
        <Grid item={true} mt={5} mb={7}>
          <Footer isMobile={true} />
        </Grid>
      </Grid>
      <SettingsDialog
        user={user}
        open={openSettings}
        handleOpenState={setOpenSettings}
        isMobile={true}
        onClose={(): void => setOpenSettings(false)}
      />
      <CardImportDialog open={openImport} isMobile={true} cards={cards} onClose={(): void => setOpenImport(false)} />
    </Fragment>
  );
};
